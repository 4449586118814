export const validateCPFCNPJ = ( value: string ): boolean => {
  value = value.replace(/[^\d]/g, '');

  if ( value.length > 11 ) return validateCNPJ( value );
  else return validateCPF( value );
};

function validateCPF( cpf: string ): boolean {
  if ( cpf.length !== 11 ) {
    return false;
  }

  let soma = 0;
  for ( let i = 0; i < 9; i++ ) {
    soma += parseInt( cpf.charAt( i )) * ( 10 - i );
  }
  let resto = 11 - ( soma % 11 );
  let digitoVerificador1 = resto >= 10 ? 0 : resto;

  soma = 0;
  for ( let i = 0; i < 10; i++ ) {
    soma += parseInt( cpf.charAt( i )) * ( 11 - i );
  }
  resto = 11 - ( soma % 11 );
  let digitoVerificador2 = resto >= 10 ? 0 : resto;

  if ( digitoVerificador1 !== parseInt( cpf.charAt( 9 )) || digitoVerificador2 !== parseInt( cpf.charAt( 10 ))) {
    return false;
  }

  return true;
}

function validateCNPJ( cnpj: string ): boolean {
  if ( cnpj.length !== 14 ) {
    return false;
  }

  let soma = 0;
  let peso = 2;
  for ( let i = 11; i >= 0; i-- ) {
    soma += parseInt( cnpj.charAt( i )) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  let digitoVerificador1 = soma % 11 < 2 ? 0 : 11 - ( soma % 11 );

  soma = 0;
  peso = 2;
  for ( let i = 12; i >= 0; i-- ) {
    soma += parseInt( cnpj.charAt( i )) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }
  let digitoVerificador2 = soma % 11 < 2 ? 0 : 11 - ( soma % 11 );

  if ( digitoVerificador1 !== parseInt( cnpj.charAt( 12 )) || digitoVerificador2 !== parseInt( cnpj.charAt( 13 ))) {
    return false;
  }

  return true;
}
