import { Outlet, useMatch, useNavigate } from "react-router-dom";

import logo from "../../../assets/logo.png";

import { AppBar, AppLayoutView, ButtonView, Content, Logo } from "./styles";
import { CaretLeft, SignOut } from "@phosphor-icons/react";
import { useStore } from "../../../store";

export const AppLayout = () => {
  const {
    logout,
  } = useStore();

  const isHome = useMatch("/");

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();

    navigate("/");
  };

  return (
    <AppLayoutView>
      <AppBar>
        <ButtonView>
          {
            !isHome && (
              <button
                onClick={() => navigate( -1 )}
              >
                <CaretLeft
                  size={ 24 }
                  weight="bold"
                />
              </button>
            )
          }
        </ButtonView>

        <Logo
          src={ logo }
        />

        <ButtonView>
          <button
            onClick={ handleLogout }
          >
            <SignOut
              size={ 24 }
              weight="bold"
            />
          </button>
        </ButtonView>
      </AppBar>

      <Content>
        <Outlet />
      </Content>
    </AppLayoutView>
  );
};
