import styled from "styled-components";

export const TitleView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => `${ theme.spacing.small } ${ theme.spacing.medium }`};

  & > span:first-of-type {
    min-height: ${({ theme }) => theme.font_size.very_large };
    font-size: ${({ theme }) => theme.font_size.very_large };
    line-height: ${({ theme }) => theme.font_size.very_large };
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text };
    white-space: nowrap;
    margin-right: ${({ theme }) => theme.spacing.medium };
  }

  & > span:last-of-type {
    min-height: ${({ theme }) => theme.font_size.medium };
    font-size: ${({ theme }) => theme.font_size.medium };
    line-height: ${({ theme }) => theme.font_size.medium };
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text };
    white-space: nowrap;
    margin-left: ${({ theme }) => theme.spacing.medium };
    text-transform: capitalize;
  }

  & > div {
    height: 1px;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.gray.hover };
  }
`;
