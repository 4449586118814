import {
  ReactNode,
  createContext,
  useContext,
} from "react";

import { User } from "../models/User";

import { useLoading } from "./hooks/useLoading";
import { useUser } from "./hooks/useUser";
import { useDialogMessage, DialogMessage } from "./hooks/useDialogMessage";

export interface UseContext {
  loading: boolean;
  openLoading: () => void;
  closeLoading: () => void;
  dialogMessage: DialogMessage | null;
  openDialogMessage: ( dialogMessage: DialogMessage ) => void;
  closeDialogMessage: () => void;
  user: User | null;
  login: ( user: User ) => void;
  logout: () => void;
};

export interface DefaultComponentProps {
  children: ReactNode;
};

export interface StateProviderProps extends DefaultComponentProps {};

export const store = createContext<UseContext>({
  loading: false,
  openLoading: () => {},
  closeLoading: () => {},
  dialogMessage: null,
  openDialogMessage: (dialogMessage) => {},
  closeDialogMessage: () => {},
  user: null,
  login: ( user ) => {},
  logout: () => {},
});

const { Provider } = store;

export const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
  const loading = useLoading();
  const dialogMessage = useDialogMessage();

  const user = useUser();

  return (
    <Provider
      value={{
        ...loading,
        ...dialogMessage,
        ...user,
      }}
    >{children}</Provider>
  );
};

export const useStore = () => useContext( store );
