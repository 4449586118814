import React from "react";
import { TitleView } from "./styles";

interface TittleProps {
  title: string;
  subTitle?: string;
};

export const Title: React.FC<TittleProps> = ({
  title,
  subTitle,
}) => {
  return (
    <TitleView>
      <span>{ title }</span>

      <div />

      <span>{ subTitle }</span>
    </TitleView>
  );
};
