import { CheckCircle, WarningCircle, XCircle } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { Dialog, IconView, Message, Type } from "./styles";
import { useStore } from "../../../store";
import { Button } from "../../Button";
import { AnimatePresence } from "framer-motion";

export const DialogMessage = () => {
  const {
    dialogMessage,
    closeDialogMessage,
  } = useStore();

  const dialogMessageIcon = {
    success:
      <CheckCircle
        weight="duotone"
        size={96}
      />,
    warning:
      <WarningCircle
        weight="duotone"
        size={96}
      />,
    error:
      <XCircle
        weight="duotone"
        size={96}
      />,
  };

  const dialogMessageTitle = {
    success: "Sucesso",
    warning: "Atenção",
    error: "Falha",
  };

  return (
    <AnimatePresence>
      {
        dialogMessage && (
          <Dialog
            open
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
            >
              <IconView $color={dialogMessage.type}>
                {
                  dialogMessageIcon[dialogMessage.type]
                }
              </IconView>

              <Type $color={dialogMessage.type}>{dialogMessageTitle[dialogMessage.type]}</Type>

              <Message>{dialogMessage.message}</Message>

              <Button
                onClick={closeDialogMessage}
              >
                OK
              </Button>
            </motion.div>
          </Dialog>
        )
      }
    </AnimatePresence>
  );
};
