import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useStore } from "../store";
import { Invoice } from "../models/Invoice";
import { getAllInvoices } from "../services/invoicesServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useInvoices = () => {
  const {
    user,
    openLoading,
    closeLoading,
  } = useStore();

  const {
    contract_id,
  } = useParams();

  const path = useLocation();
  const navigate = useNavigate();

  const [ invoices, setInvoices ] = useState<Array<Invoice>>([]);

  const loadInvoices = async () => {
    openLoading();

    const res = await getAllInvoices( user?.token ?? "", Number( contract_id ?? 0 ));

    closeLoading();

    if( Boolean( res.data )) {
      setInvoices( res.data as Array<Invoice> );
    } else {
      enqueueSnackbar(res.message, {
        variant: res.data ? 'success' : 'error',
      });
    }
  };

  useEffect(() => {
    loadInvoices();
  }, []);

  return {
    invoices,
    path,
    navigate,
  };
};
