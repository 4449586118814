import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useStore } from "../store";
import loginSchema from "../schemas/loginSchema";
import { LoginForm } from "../types/Forms";
import { User } from "../models/User";
import { auth } from "../services/authServices";
import { useHookFormMask } from "use-mask-input";

export const useLogin = () => {
  const navigate = useNavigate();

  const {
    login,
    openLoading,
    closeLoading,
  } = useStore();

  const form = useForm({
    resolver: yupResolver( loginSchema ),
    defaultValues: {
      cpfcnpj: '',
      senha: '',
    },
    mode: 'all',
  });

  const registerWithMask = useHookFormMask( form.register );

  const onSubmit = async ( data: LoginForm ) => {
    openLoading();

    const res = await auth( data );

    closeLoading();

    if( Boolean( res.data )) {
      login( res.data as User );
    } else {
      enqueueSnackbar(res.message, {
        variant: res.data ? 'success' : 'error',
      });
    }
  };

  const handleSubmit = form.handleSubmit( onSubmit );

  const handleNavigateToRegister = () => navigate('/register');

  return {
    form,
    handleSubmit,
    handleNavigateToRegister,
    registerWithMask,
  };
};
