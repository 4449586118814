import styled from "styled-components";

export const Card = styled.button`
  border: none;
  box-shadow: ${({ theme }) => theme.shadow.default };
  background-color: ${({ theme }) => theme.colors.background };
  border-radius: ${({ theme }) => theme.border_radius.medium };
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium };
  overflow: hidden;
  transition: all .2s;

  &:active {
    scale: .98;
    opacity: .8;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;

export const Content = styled.pre`
  white-space: pre-wrap;
  text-align: left;
  font-weight: 500;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.text };
  margin-top: ${({ theme }) => theme.spacing.medium };
  margin-bottom: ${({ theme }) => theme.spacing.medium };
`;

export const Title = styled.span`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-height: ${({ theme }) => theme.font_size.large };
  font-size: ${({ theme }) => theme.font_size.large };
  line-height: ${({ theme }) => theme.font_size.large };
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text };

  & > span {
    text-transform: capitalize;
  }
`;

export const Label = styled.span`
  text-align: left;
  min-height: ${({ theme }) => theme.font_size.medium };
  font-size: ${({ theme }) => theme.font_size.medium };
  line-height: ${({ theme }) => theme.font_size.medium };
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text };
`;

export const Date = styled.span`
  text-align: left;
  min-height: ${({ theme }) => theme.font_size.medium };
  font-size: ${({ theme }) => theme.font_size.medium };
  line-height: ${({ theme }) => theme.font_size.medium };
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.default };
`;

export const Maturity = styled.span`
  text-align: left;
  min-height: ${({ theme }) => theme.font_size.small };
  font-size: ${({ theme }) => theme.font_size.small };
  line-height: ${({ theme }) => theme.font_size.small };
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text };
`;

export const InvoiceMonth = styled.span`
  text-align: left;
  min-height: ${({ theme }) => theme.font_size.small };
  font-size: ${({ theme }) => theme.font_size.small };
  line-height: ${({ theme }) => theme.font_size.small };
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text };
  margin-top: ${({ theme }) => theme.spacing.very_small };
`;

export const InvoiceMaturity = styled.span`
  text-align: right;
  min-height: ${({ theme }) => theme.font_size.small };
  font-size: ${({ theme }) => theme.font_size.small };
  line-height: ${({ theme }) => theme.font_size.small };
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text };
  margin-top: ${({ theme }) => theme.spacing.very_small };
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

export const Value = styled.span`
  text-align: left;
  min-height: ${({ theme }) => theme.font_size.very_large };
  font-size: ${({ theme }) => theme.font_size.very_large };
  line-height: ${({ theme }) => theme.font_size.very_large };
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.default };
`;
