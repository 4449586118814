import { IconProps, CheckCircle, Circle, XCircle } from "@phosphor-icons/react";
import { SituationType } from "../../models/Situation";
import { StatusView, Variant } from "./styles";

interface SituationProps {
  status: SituationType;
};

export const Situation: React.FC<SituationProps> = ({
  status,
}) => {
  const statusVariant: {
    [key in SituationType]: Variant;
  } = {
    "Disponível": "primary",
    "Vencido": "error",
    "Pago": "success",
  };

  return (
    <StatusView $variant={ statusVariant[ status ]}>
      <span>{ status }</span>
    </StatusView>
  );
};
