import styled from "styled-components";

export type Variant = "success" | "primary" | "error";

export const StatusView = styled.div<{
  $variant: Variant;
}>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.small };
  background-color: ${({ theme, $variant }) => theme.colors[ $variant ].default }33;
  border-radius: ${({ theme }) => theme.border_radius.medium };
  color: ${({ theme, $variant }) => theme.colors[ $variant ].default };

  & > svg {
    margin-right: ${({ theme }) => theme.spacing.small };
  }

  & > span {
    min-height: ${({ theme }) => theme.font_size.small };
    font-size: ${({ theme }) => theme.font_size.small };
    line-height: ${({ theme }) => theme.font_size.small };
    font-weight: 600;
    text-transform: uppercase;
  }
`;
