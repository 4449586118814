const defaultColors = {
  primary: {
    default: "#2664C4",
    hover: "#0047B6",
  },

  secondary: {
    default: "#FB6219",
    hover: "#CB4708",
  },

  selected: {
    default: "#BAD4F6",
    hover: "#7BA4DC",
  },

  gray: {
    default: "#DAE5F3",
    hover: "#B1BECF",
  },

  success: {
    default: "#00973C",
  },

  warning: {
    default: "#D37200",
  },

  error: {
    default: "#CE2626",
  },
};

const defaultSpacing = {
  none: "0",
  very_small: "4px",
  small: "8px",
  medium: "16px",
  very_medium: "24px",
  large: "32px",
  very_large: "48px",
  x_very_large: "72px",
};

const defaultFontSize = {
  very_small: "12px",
  small: "14px",
  medium: "16px",
  large: "18px",
  very_large: "24px",
};

const defaultBorderRadius = {
  medium: "8px",
};

const defaultBreakpoints = {
  small: "600px",
  medium: "900px",
  large: "1200px"
};

const defaultShadow = {
  default: "0px 0px 8px rgba(38, 100, 196, 0.4)",
};

export const theme = {
  light: {
    spacing: {
      ...defaultSpacing,
    },
    border_radius: {
      ...defaultBorderRadius,
    },
    font_size: {
      ...defaultFontSize,
    },
    breakpoints: {
      ...defaultBreakpoints,
    },
    shadow: {
      ...defaultShadow,
    },
    colors: {
      background: "#FCFBFD",
      text: "#181B1E",
      ...defaultColors,
    },
  },

  dark: {
    spacing: {
      ...defaultSpacing,
    },
    border_radius: {
      ...defaultBorderRadius,
    },
    font_size: {
      ...defaultFontSize,
    },
    breakpoints: {
      ...defaultBreakpoints,
    },
    shadow: {
      ...defaultShadow,
    },
    colors: {
      background: "#181B1E",
      text: "#FCFBFD",
      ...defaultColors,
    },
  },
};
