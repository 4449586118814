import { Outlet } from "react-router-dom";

import logo from "../../../assets/logo.png";

import { AuthLayoutView, Content, Logo } from "./styles";

export const AuthLayout = () => {
  return (
    <AuthLayoutView>
      <Logo src={ logo } />

      <Content>
        <Outlet />
      </Content>
    </AuthLayoutView>
  );
};
