import styled from "styled-components";

export const AppLayoutView = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background };
  color: ${({ theme }) => theme.colors.text };
`;

export const AppBar = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.medium };
`;

export const ButtonView = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;

  & > button {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary.default };
    border: none;
    width: 100%;
    height: 100%;
    transition: all .2s;
  }

  & > button:active {
    opacity: .6;
    scale: .8;
  }
`;

export const Logo = styled.img`
  height: 32px;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;
