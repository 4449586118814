import { useNavigate } from "react-router-dom";
import { ContractCard } from "../../../components/Cards/ContractCard";
import { Title } from "../../../components/Title";
import { useContracts } from "../../../controllers/contractsController";
import { GridView } from "../../../components/GridView";

export const Contracts = () => {
  const navigate = useNavigate();

  const {
    contracts,
  } = useContracts();

  return (
    <>
      <Title
        title="Contratos"
      />

      <GridView $minChildHeight="140px">
        {
          contracts.map(( contract ) => (
            <ContractCard
              key={ contract.contrato }
              onClick={() => navigate(`/contract/${ contract.contrato }`)}
              { ...contract }
            />
          ))
        }
      </GridView>
    </>
  );
};
