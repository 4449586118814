import React from "react";
import { Contract } from "../../../models/Contract";
import { Row, Label, Maturity, Value, Card, CardContent, Title } from "../styles";
import { monetaryFormat } from "../../../utils/monetaryFormat";
import { Status } from "../../Status";

interface ContractCardProps extends Contract {
  onClick: () => void;
}

export const ContractCard: React.FC<ContractCardProps> = ({
  planointernet,
  vencimento,
  planointernet_valor,
  status,
  onClick,
}) => {
  return (
    <Card
      onClick={ onClick }
    >
      <CardContent>
        <Title>{ planointernet }</Title>

        <Label>Vencimento</Label>

        <Maturity>{ vencimento }/mês</Maturity>

        <Row>
          <Value>{ monetaryFormat( planointernet_valor ?? 0 )}</Value>

          <Status
            status={ status ?? "Cancelado"}
          />
        </Row>
      </CardContent>
    </Card>
  );
};
