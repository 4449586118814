import { format, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Invoice } from "../../../models/Invoice";
import { monetaryFormat } from "../../../utils/monetaryFormat";
import { Situation } from "../../Situation"
import { Row, Card, CardContent, InvoiceMonth, Label, Title, Value, Column, InvoiceMaturity } from "../styles";
import { stringDateToUTCDate } from "../../../utils/stringDateToUTCDate";

interface InvoiceCardProps extends Invoice {
  onClick: () => void;
}

export const InvoiceCard: React.FC<InvoiceCardProps> = ({
  onClick,
  vencimento,
  valorcorrigido,
  situacao,
}) => {
  const dueDate = stringDateToUTCDate( vencimento );

  return (
    <Card onClick={ onClick }>
      <CardContent>
        <Title>Fatura de <span>{ format( dueDate, "LLLL", { locale: ptBR })}</span></Title>

        <Row>
          <Column>
            <Label>Vencimento</Label>

            <InvoiceMonth>{ format( dueDate, "dd/MM/yyyy", { locale: ptBR })}</InvoiceMonth>
          </Column>

          {
            situacao === "Vencido" && (
              <Column>
                <Label>Vencida a</Label>

                <InvoiceMaturity>{ formatDistanceToNow( dueDate, { locale: ptBR })}</InvoiceMaturity>
              </Column>
            )
          }
        </Row>

        <Row>
          <Value>{ monetaryFormat( valorcorrigido ?? 0 )}</Value>

          <Situation
            status={ situacao ?? "Disponível"}
          />
        </Row>
      </CardContent>
    </Card>
  );
};
