import styled from "styled-components";
import { StatusContractType } from "../../../models/ServiceStatus";

export const StatusContract = styled.div<{
  $status: StatusContractType,
}>`
  padding: ${({ theme }) => `${ theme.spacing.none } ${ theme.spacing.medium }`};
  margin-top: ${({ theme }) => theme.spacing.small };
  margin-bottom: ${({ theme }) => theme.spacing.medium };

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    padding: ${({ theme }) => theme.spacing.medium};
    border-radius: ${({ theme }) => theme.border_radius.medium };
    min-height: ${({ theme }) => theme.font_size.large };
    font-size: ${({ theme }) => theme.font_size.large };
    line-height: ${({ theme }) => theme.font_size.large };
    font-weight: 700;

    ${({ theme, $status }) => {
      const statusColorObj: {
        [key in StatusContractType]: string;
      } = {
        Conectado: theme.colors.success.default,
        Bloqueado: theme.colors.warning.default,
        Desconectado: theme.colors.error.default,
      };

      return {
        "color": `${ statusColorObj[ $status ]};`,
        "background-color": `${ statusColorObj[ $status ]}33;`,
      };
    }}
  }
`;
