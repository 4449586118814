import { AnimatePresence, motion } from "framer-motion";
import QRCode from "react-qr-code";
import { Dialog, QRCodeView } from "./styles";
import { Button } from "../../Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { useQRCodeModal } from "../../../controllers/QRCodeModalController";

export interface QRCodeModalProps {
  open: boolean
  onClose: () => void
  pixCode: string
}

export const QRCodeModal: React.FC<QRCodeModalProps> = ({
  open,
  pixCode,
  onClose
}) => {
  const {
    handleCopy,
  } = useQRCodeModal();

  return (
    <AnimatePresence>
      {
        open && (
          <Dialog
            open
            onClose={onClose}
            onCancel={onClose}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
            >
              <h1>Leia ou copie o código pix</h1>

              <QRCodeView>
                <QRCode
                  value={pixCode}
                />
              </QRCodeView>

              <CopyToClipboard onCopy={handleCopy} text={pixCode}>
                <a>Copiar Código</a>
              </CopyToClipboard>


              <Button
                onClick={onClose}
              >
                FECHAR
              </Button>
            </motion.div>
          </Dialog>
        )
      }
    </AnimatePresence>
  );
};
