import api from "../config/api";
import { Contract } from "../models/Contract";
import { StatusContractType } from "../models/ServiceStatus";
import { ServiceResponse } from "../types/Response";

export const getAllContracts = async ( token: string ): Promise<ServiceResponse<Array<Contract>>> => {
  try {
    const {
      data
    } = await api.get("/contracts", {
      headers: {
        Authorization: `Bearer ${ token }`
      }
    });

    return {
      data,
      message: ""
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
}

export const getStatusContract = async ( token: string, id: number ): Promise<ServiceResponse<StatusContractType>> => {
  try {
    const {
      data
    } = await api.get(`/contracts/${ id }/status`, {
      headers: {
        Authorization: `Bearer ${ token }`
      }
    });

    return {
      data: data.mensagem,
      message: ""
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
}

export const unlockService = async ( token: string, id: number ): Promise<ServiceResponse<never>> => {
  try {
    const {
      data
    } = await api.get(`/contracts/${ id }/unlock`, {
      headers: {
        Authorization: `Bearer ${ token }`
      }
    });

    return {
      data: data.sucesso,
      message: data.mensagem
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
};
