import { StatusType } from "../../models/Status";
import { StatusView, Variant } from "./styles";

interface StatusProps {
  status: StatusType;
};

export const Status: React.FC<StatusProps> = ({
  status,
}) => {
  const statusVariant: {
    [key in StatusType]: Variant;
  } = {
    Ativo: "success",
    Inativo: "warning",
    Cancelado: "error",
    Aberto: "warning",
    Encerrado: "success",
    Pendente: "error",
    "Em execução": "primary"
  };

  return (
    <StatusView $variant={ statusVariant[ status ]}>
      <span>{ status }</span>
    </StatusView>
  );
};
