import { enqueueSnackbar } from "notistack";

export const useQRCodeModal = () => {
  const handleCopy = () => {
    enqueueSnackbar("Código copiado para área de transferência", {
      variant: "success",
    });
  };

  return {
    handleCopy,
  };
};
