import styled from "styled-components";

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.small };

  & > button, & > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: none;
    padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.large}`};
    color: ${({ theme }) => theme.colors.primary.default };
    background-color: ${({ theme }) => theme.colors.background };
    transition: all .2s;
    height: 64px;

    &:active {
      background-color: ${({ theme }) => theme.colors.gray.default };
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: all .2s;
    }

    &:active > div {
      scale: .98;
    }

    & > div > div {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
    }

    & > div > svg {
      margin-right: ${({ theme }) => theme.spacing.large };
    }

    & > div > div > strong {
      min-height: ${({ theme }) => theme.font_size.very_large };
      font-size: ${({ theme }) => theme.font_size.very_large };
      line-height: ${({ theme }) => theme.font_size.very_large };
      font-weight: 700;
    }

    & > div > div > span {
      min-height: ${({ theme }) => theme.font_size.medium };
      font-size: ${({ theme }) => theme.font_size.medium };
      line-height: ${({ theme }) => theme.font_size.medium };
      color: ${({ theme }) => theme.colors.gray.hover };
      font-weight: 400;
      margin-top: ${({ theme }) => theme.spacing.small };
    }
  }
`;
