import styled from "styled-components";

export const AuthLayoutView = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.very_large};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
`;

export const Logo = styled.img`
  height: 72px;
`;

export const Content = styled.section`
  display: flex;
  flex: 1;
  width: 100%;
`;
