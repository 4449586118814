import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useStore } from "../store";
import { getStatusContract, unlockService } from "../services/contractsServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StatusContractType } from "../models/ServiceStatus";

export const useContract = () => {
  const {
    user,
    openLoading,
    closeLoading,
    openDialogMessage,
  } = useStore();

  const path = useLocation();
  const navigate = useNavigate();

  const {
    contract_id,
  } = useParams();

  const [ statusContract, setStatusContract ] = useState<StatusContractType>("Desconectado");

  const loadStatusContract = async () => {
    openLoading();

    const res = await getStatusContract( user?.token ?? "", Number( contract_id ));

    closeLoading();

    if( Boolean( res.data )) {
      setStatusContract( res.data as StatusContractType );
    } else {
      enqueueSnackbar(res.message, {
        variant: res.data ? 'success' : 'error',
      });
    }
  };

  const handleUnlockService = async () => {
    openLoading();

    const res = await unlockService( user?.token ?? "", Number( contract_id ));

    closeLoading();

    let message = "";

    if( res.message ) message = res.message;
    else if( Boolean( res.data )) message = "Solicitação bem sucedida!";
    else message = "Falha na solicitação";

    openDialogMessage({
      type: Boolean( res.data ) ? "success" : "error",
      message,
    });
  };

  useEffect(() => {
    loadStatusContract();
  }, []);

  return {
    path,
    statusContract,
    navigate,
    handleUnlockService,
  };
};
