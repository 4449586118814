import { useMemo, useState } from "react";

export interface DialogMessage {
  type: "success" | "warning" | "error";
  message: string;
}

export function useDialogMessage() {
  const [ dialogMessage, setDialogMessage ] = useState<DialogMessage | null>( null );

  const openDialogMessage = ( dialogMessage: DialogMessage ) => setDialogMessage( dialogMessage );

  const closeDialogMessage = () => setDialogMessage( null );

  return useMemo(() => ({
    dialogMessage,
    openDialogMessage,
    closeDialogMessage,
  }), [
    dialogMessage,
  ]);
};
