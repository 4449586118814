import {
  createBrowserRouter,
} from "react-router-dom";

import {
  AuthLayout,
} from "../components/layouts/AuthLayout";
import { Login } from "../pages/Auth/Login";

const router = createBrowserRouter([
  {
    path: "/",
    Component: AuthLayout,
    children: [
      {
        path: "",
        Component: Login,
      }
    ]
  },
]);

export default router;
