import { Title } from "../../../components/Title";
import { StatusContract } from "./styles";
import { Invoice, LockKeyOpen } from "@phosphor-icons/react";
import { useContract } from "../../../controllers/contractController";
import { Actions } from "../../../components/Actions";

export const Contract = () => {
  const {
    path,
    statusContract,
    navigate,
    handleUnlockService,
  } = useContract();

  return (
    <>
      <Title
        title="Status de conexão"
      />

      <StatusContract $status={ statusContract }>
        <div>{ statusContract }</div>
      </StatusContract>

      <Title
        title="Central do cliente"
      />

      <Actions>
        {/* <button
          onClick={() => navigate(`${ path.pathname }/tickets`)}
        >
          <div>
            <Megaphone
              mirrored
              weight="bold"
              size={ 32 }
            />

            <div>
              <strong>Chamados</strong>

              <span>Solicitar suporte técnico</span>
            </div>
          </div>
        </button> */}

        <button
          onClick={() => navigate(`${ path.pathname }/invoice`)}
        >
          <div>
            <Invoice
              weight="bold"
              size={ 32 }
            />

            <div>
              <strong>Faturas</strong>

              <span>Pagar faturas</span>
            </div>
          </div>
        </button>

        <button
          onClick={ handleUnlockService }
        >
          <div>
            <LockKeyOpen
              weight="bold"
              size={ 32 }
            />

            <div>
              <strong>Desbloquear</strong>

              <span>Promessa de pagamento</span>
            </div>
          </div>
        </button>

        {/* <button>
          <div>
            <Globe
              weight="bold"
              size={ 32 }
            />

            <div>
              <strong>Extrato de uso</strong>

              <span>Uso de dados por mês</span>
            </div>
          </div>
        </button> */}
      </Actions>
    </>
  );
};
