import styled from "styled-components";

export const SituationView = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
  padding-bottom: ${({ theme }) => theme.spacing.very_medium};
`;

export const InvoiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.none} ${theme.spacing.medium}`};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  & > span:first-of-type {
    min-height: ${({ theme }) => theme.font_size.medium};
    font-size: ${({ theme }) => theme.font_size.medium};
    line-height: ${({ theme }) => theme.font_size.medium};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 500;
  }

  & > span:last-of-type {
    min-height: ${({ theme }) => theme.font_size.very_large};
    font-size: ${({ theme }) => theme.font_size.very_large};
    line-height: ${({ theme }) => theme.font_size.very_large};
    color: ${({ theme }) => theme.colors.primary.default};
    font-weight: 700;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray.hover};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;
