import * as yup from "yup"
import { validateCPFCNPJ } from "../utils/validateCPFCNPJ";

const message = 'Campo Obrigatório';

const loginSchema = yup.object({
  cpfcnpj: yup
    .string()
    .test({
      name: "validateCPFCNPJ",
      exclusive: false,
      params: {},
      message: "CPF/CNPJ inválido",
      test: function ( value ) {
        return Boolean( value ) ? validateCPFCNPJ( value ?? "") : true;
      }
    })
    .required(message),
  senha: yup.string().required(message),
});

export default loginSchema;
