import styled from "styled-components";

export const Button = styled.button`
  padding: ${({ theme }) => theme.spacing.medium };
  border-radius: ${({ theme }) => theme.border_radius.medium };
  border: none;
  background-color: ${({ theme }) => theme.colors.primary.default };
  color: ${({ theme }) => theme.colors.background };

  min-height: ${({ theme }) => theme.font_size.large };
  font-size: ${({ theme }) => theme.font_size.large };
  line-height: ${({ theme }) => theme.font_size.large };
  font-weight: 700;
  transition: all .2s;

  &:not(:disabled):active {
    opacity: .8;
    scale: .95;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.hover };
    color: ${({ theme }) => theme.colors.gray.default };
  }
`;
