import { format, subMonths } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Actions } from "../../../components/Actions";
import { Title } from "../../../components/Title";
import { useInvoice } from "../../../controllers/invoiceController";
import { stringDateToUTCDate } from "../../../utils/stringDateToUTCDate";
import { Divider, InvoiceInfo, Row, SituationView } from "./styles";
import { monetaryFormat } from "../../../utils/monetaryFormat";
import { Situation } from "../../../components/Situation";
import { QRCodeModal } from "../../../components/Modals/QRCodeModal";
import { SendMailModal } from "../../../components/Modals/SendMailModal";
import { EnvelopeSimpleOpen } from "@phosphor-icons/react";

export const Invoice = () => {
  const {
    invoice,
    openPixModal,
    openSendMailModal,
    handleDownload,
    handleCopy,
    setOpenPixModal,
    setOpenSendMailModal,
  } = useInvoice();

  return (
    <>
      <Title
        title="Fatura"
        subTitle={`Mês de ${ format( subMonths( stringDateToUTCDate( invoice.vencimento ), 1 ), 'LLLL', {
          locale: ptBR,
        })}`}
      />

      <SituationView>
        <Situation
          status={invoice.situacao ?? "Disponível"}
        />

      </SituationView>

      <InvoiceInfo>
        <Row>
          <span>Valor</span>

          <span>{ monetaryFormat( invoice.valor )}</span>
        </Row>

        {
          invoice.valorcorrigido - invoice.valor > 0 && (
            <>
              <Row>
                <span>Multa</span>

                <span>{ monetaryFormat( invoice.valorcorrigido - invoice.valor )}</span>
              </Row>

              <Divider />

              <Row>
                <span>Total</span>

                <span>{ monetaryFormat( invoice.valorcorrigido )}</span>
              </Row>
            </>
          )
        }
      </InvoiceInfo>

      <InvoiceInfo>
        {
          invoice.vencimento_atualizado !== invoice.vencimento ? (
            <Row>
              <span>Vencimento Atualizado</span>

              <span>{format(stringDateToUTCDate(invoice.vencimento_atualizado), 'dd/MM/yyyy')}</span>
            </Row>
          ) : (
            <Row>
              <span>Vencimento</span>

              <span>{format(stringDateToUTCDate(invoice.vencimento), 'dd/MM/yyyy')}</span>
            </Row>
          )
        }

        {
          invoice.data_pagamento && (
            <Row>
              <span>Pagamento</span>

              <span>{ format( stringDateToUTCDate( invoice.data_pagamento ), 'dd/MM/yyyy')}</span>
            </Row>
          )
        }
      </InvoiceInfo>

      {
        invoice.situacao !== "Pago" && (
          <Actions>
            <button onClick={() => setOpenPixModal(true)}>
              <div>
                <div>
                  <strong>Pagar via Pix</strong>
                </div>
              </div>
            </button>

            <button onClick={handleDownload}>
              <div>
                <div>
                  <strong>Baixar 2ª Via</strong>
                </div>
              </div>
            </button>

            <button onClick={() => setOpenSendMailModal(true)}>
              <div>
                <div>
                  <strong>Enviar Por E-mail</strong>
                </div>
              </div>
            </button>

            <CopyToClipboard onCopy={handleCopy} text={invoice.linhadigitavel}>
              <button>
                <div>
                  <div>
                    <strong>Copiar Código de Barras</strong>
                  </div>
                </div>
              </button>
            </CopyToClipboard>
          </Actions>
        )
      }

      <QRCodeModal
        open={openPixModal}
        onClose={() => setOpenPixModal(false)}
        pixCode={invoice.codigopix}
      />

      <SendMailModal
        open={openSendMailModal}
        onClose={() => setOpenSendMailModal(false)}
      />
    </>
  );
};
