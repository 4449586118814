import { saveAs } from "file-saver";
import api from "../config/api";
import { Invoice } from "../models/Invoice";
import { ServiceResponse } from "../types/Response";

export const getAllInvoices = async ( token: string, id: number ): Promise<ServiceResponse<Array<Invoice>>> => {
  try {
    const {
      data
    } = await api.get(`/contracts/${ id }/invoices`, {
      headers: {
        Authorization: `Bearer ${ token }`
      }
    });

    return {
      data,
      message: ""
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
};

export const downloadInvoice = async ( token: string, contract_id: number, invoice: Invoice ): Promise<ServiceResponse<boolean>> => {
  try {
    const res = await api.get(`/contracts/${ contract_id }/invoices/${ invoice.id }/download`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${ token }`
      }
    });

    saveAs( res.data, `fatura_infoturbo_${ invoice.id }_${ invoice.vencimento_atualizado.replaceAll("-", "")}.pdf`, {
      autoBom: false,
    });

    return {
      data: true,
      message: "",
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
};

export const sendMailInvoice = async ( token: string, contract_id: number, invoice_id: number, email: string ): Promise<ServiceResponse<boolean>> => {
  try {
    const {
      data
    } = await api.post(`/contracts/${ contract_id }/invoices/${ invoice_id }/send-mail`, {
      email,
    }, {
      headers: {
        Authorization: `Bearer ${ token }`
      }
    });

    return {
      data: Boolean( data.success ),
      message: data.message,
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
};
