import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  & > .inputs-view {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.medium};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.medium };
  }

  & > .inputs-view > .input-view {
    display: flex;
    flex-direction: column;
  }

  & > .inputs-view > .input-view > .input-label {
    margin-bottom: ${({ theme }) => theme.spacing.very_small};
    margin-left: ${({ theme }) => theme.spacing.small};
    color: ${({ theme }) => theme.colors.primary.default};

    min-height: ${({ theme }) => theme.font_size.medium};
    font-size: ${({ theme }) => theme.font_size.medium};
    line-height: ${({ theme }) => theme.font_size.medium};
    font-weight: 700;
  }

  & > .inputs-view > .input-view > input {
    padding: ${({ theme }) => theme.spacing.medium};
    border-radius: ${({ theme }) => theme.border_radius.medium};
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
    color: ${({ theme }) => theme.colors.primary.default};

    min-height: ${({ theme }) => theme.font_size.medium};
    font-size: ${({ theme }) => theme.font_size.medium};
    line-height: ${({ theme }) => theme.font_size.medium};
    font-weight: 700;
    background-color: ${({ theme }) => theme.colors.background};

    transition: all .2s;
  }

  & > .inputs-view > .input-view > input:focus {
    box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.primary.default}66;
  }

  & > .inputs-view > .input-view > input::placeholder {
    color: ${({ theme }) => theme.colors.gray.hover};
    font-weight: 600;
  }

  & > .inputs-view > .input-view > .input-error-message {
    margin-top: ${({ theme }) => theme.spacing.very_small};
    margin-left: ${({ theme }) => theme.spacing.medium};
    color: ${({ theme }) => theme.colors.error.default};

    min-height: ${({ theme }) => theme.font_size.small};
    font-size: ${({ theme }) => theme.font_size.small};
    line-height: ${({ theme }) => theme.font_size.small};
    font-weight: 500;
  }

  & > .actions-view {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing.medium};
  }
`;
