import api from "../config/api";
import { User } from "../models/User";
import { LoginForm } from "../types/Forms";
import { ServiceResponse } from "../types/Response";

export const auth = async ( loginForm: LoginForm ): Promise<ServiceResponse<User>> => {
  try {
    const {
      data
    } = await api.post("/auth", loginForm );

    return {
      data,
      message: ""
    };
  } catch ( error: any ) {
    return {
      data: false,
      message: error.message,
    };
  }
};
