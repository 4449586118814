import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useStore } from "../store";
import { Contract } from "../models/Contract";
import { getAllContracts } from "../services/contractsServices";

export const useContracts = () => {
  const {
    user,
    openLoading,
    closeLoading,
  } = useStore();

  const [ contracts, setContracts ] = useState<Array<Contract>>([]);

  const loadContracts = async () => {
    openLoading();

    const res = await getAllContracts( user?.token ?? "");

    closeLoading();

    if( Boolean( res.data )) {
      setContracts( res.data as Array<Contract> );
    } else {
      enqueueSnackbar(res.message, {
        variant: res.data ? 'success' : 'error',
      });
    }
  };

  useEffect(() => {
    loadContracts();
  }, []);

  return {
    contracts
  };
};
