import {
  createBrowserRouter,
} from "react-router-dom";

import {
  AppLayout,
} from "../components/layouts/AppLayout";

import { Contracts } from "../pages/App/Contracts";
import { Contract } from "../pages/App/Contract";
import { Invoices } from "../pages/App/Invoices";
import { Tickets } from "../pages/App/Tickets";
import { Invoice } from "../pages/App/Invoice";

const router = createBrowserRouter([
  {
    path: "/",
    Component: AppLayout,
    children: [
      {
        path: "",
        Component: Contracts,
      },
      {
        path: "/contract/:contract_id",
        children: [
          {
            path: "",
            Component: Contract,
          },
          {
            path: "/contract/:contract_id/invoice",
            Component: Invoices,
          },
          {
            path: "/contract/:contract_id/invoice/:invoice_id",
            Component: Invoice,
          },
          // {
          //   path: "/contract/:contract_id/tickets",
          //   Component: Tickets,
          // },
        ]
      },
    ]
  },
]);

export default router;
