import { Title } from "../../../components/Title";
import { useInvoices } from "../../../controllers/invoicesController";
import { InvoiceCard } from "../../../components/Cards/InvoiceCard";
import { GridView } from "../../../components/GridView";

export const Invoices = () => {
  const {
    invoices,
    path,
    navigate,
  } = useInvoices();

  return (
    <>
      <Title
        title="Faturas"
      />

      <GridView $minChildHeight="140px">
        {
          invoices.map(( invoice ) => (
            <InvoiceCard
              key={ invoice.id }
              onClick={() => navigate(`${ path.pathname }/${ invoice.id }`, {
                state: {
                  invoice
                },
              })}
              { ...invoice }
            />
          ))
        }
      </GridView>
    </>
  );
};
