import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import sendMailSchema from "../schemas/sendMailSchema";
import { SendMailForm } from "../types/Forms";
import { useStore } from "../store";
import { sendMailInvoice } from "../services/invoicesServices";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const useSendMailModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const {
    user,
    openLoading,
    closeLoading,
  } = useStore();

  const {
    contract_id,
    invoice_id,
  } = useParams();

  const form = useForm({
    resolver: yupResolver( sendMailSchema ),
    defaultValues: {
      email: ''
    },
    mode: 'all',
  });

  const onSubmit = async ( data: SendMailForm ) => {
    openLoading();

    const res = await sendMailInvoice(user?.token ?? "", Number( contract_id ), Number( invoice_id ), data.email );

    if( data ) {
      form.reset();

      onClose();
    }

    enqueueSnackbar(res.message, {
      variant: res.data ? 'success' : 'error',
    });

    closeLoading();
  };

  const handleSubmit = form.handleSubmit( onSubmit );

  useEffect(() => {
    if( !open ) form.reset();
  }, [ open ]);

  return {
    form,
    handleSubmit,
  };
};
