import { AnimatePresence, motion } from "framer-motion";
import QRCode from "react-qr-code";
import { Dialog, HeaderModal } from "./styles";
import { Button } from "../../Button";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSendMailModal } from "../../../controllers/SendMailModalController";
import { Form } from "../../Form";
import { X } from "@phosphor-icons/react";

export interface SendMailProps {
  open: boolean
  onClose: () => void
}

export const SendMailModal: React.FC<SendMailProps> = ({
  open,
  onClose
}) => {
  const {
    form: {
      register,
      formState: {
        isValid,
        errors,
      }
    },
    handleSubmit,
  } = useSendMailModal({
    open,
    onClose
  });

  return (
    <AnimatePresence>
      {
        open && (
          <Dialog
            open
            onClose={onClose}
            onCancel={onClose}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
            >
              <HeaderModal>
                <button onClick={onClose}>
                  <X
                    size={16}
                  />
                </button>
              </HeaderModal>

              <h1>Email para envio da fatura</h1>

              <Form onSubmit={handleSubmit}>
                <div className="inputs-view">

                  <div className="input-view">
                    <label className="input-label" htmlFor="email">Email</label>

                    <input
                      type="email"
                      id="email"
                      {...register("email")}
                      placeholder="Email"
                    />

                    <span className="input-error-message">{errors.email?.message ?? ""}</span>
                  </div>
                </div>

                <Button
                  type="submit"
                  disabled={!isValid}
                >
                  ENVIAR
                </Button>
              </Form>
            </motion.div>
          </Dialog>
        )
      }
    </AnimatePresence>
  );
};
