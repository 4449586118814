import styled from "styled-components";

export const GridView = styled.div<{
  $minChildHeight?: string;
  $maxChildHeight?: string;
  $paddingBottom?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.medium };
  overflow-y: auto;
  padding: ${({ theme }) => `${ theme.spacing.medium } ${ theme.spacing.medium }`};

  ${({ theme, $paddingBottom }) => $paddingBottom ? `padding-bottom: ${ theme.spacing.x_very_large };` : ""}

  & > button {
    ${({ $minChildHeight, $maxChildHeight }) => `
      ${ $minChildHeight ? `min-height: ${ $minChildHeight };` : ""}
      ${ $maxChildHeight ? `max-height: ${ $maxChildHeight };` : ""}
    `}
  }
`;
