import { motion } from "framer-motion";
import styled from "styled-components";

export const Dialog = styled(motion.dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  inset: ${({ theme }) => theme.spacing.none };
  width: 100%;
  height: 100%;
  border: none;
  background-color: ${({ theme }) => theme.colors.background }E6;
  padding: ${({ theme }) => theme.spacing.large };

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background };
    box-shadow: ${({ theme }) => theme.shadow.default };
    border-radius: ${({ theme }) => theme.border_radius.medium };
    padding: ${({ theme }) => theme.spacing.medium };
    overflow: hidden;
  }

  & > div > button {
    width: 100%;
  }
`;

export const IconView = styled.div<{
  $color: "success" | "warning" | "error";
}>`
  color: ${({ theme, $color }) => theme.colors[ $color ].default };
`;

export const Type = styled.span<{
  $color: "success" | "warning" | "error";
}>`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.very_small };
  margin-bottom: ${({ theme }) => theme.spacing.large };
  color: ${({ theme, $color }) => theme.colors[ $color ].default };
  font-size: ${({ theme }) => theme.font_size.very_large };
  font-weight: 700;
`;

export const Message = styled.span`
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.large };
  color: ${({ theme }) => theme.colors.text };
`;
