import { motion } from "framer-motion";
import styled from "styled-components";

export const Dialog = styled(motion.dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  inset: ${({ theme }) => theme.spacing.none };
  width: 100%;
  height: 100%;
  border: none;
  background-color: ${({ theme }) => theme.colors.background }E6;
  padding: ${({ theme }) => theme.spacing.large };

  & > div > h1 {
    margin-bottom: ${({ theme }) => theme.spacing.large };
    color: ${({ theme }) => theme.colors.primary.default };
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background };
    box-shadow: ${({ theme }) => theme.shadow.default };
    border-radius: ${({ theme }) => theme.border_radius.medium };
    padding: ${({ theme }) => theme.spacing.medium };
    overflow: hidden;
  }

  & > div > a {
    width: 100%;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.large };
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary.default };
    font-weight: 600;
  }

  & > div > a:active {
    opacity: .8;
  }

  & > div > button {
    width: 100%;
  }
`;

export const QRCodeView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.large };
`;
