import { useLocation, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { Invoice } from "../models/Invoice";
import { useStore } from "../store";
import { useState } from "react";
import { downloadInvoice } from "../services/invoicesServices";

export const useInvoice = (): {
  invoice: Invoice
  openPixModal: boolean
  openSendMailModal: boolean
  handleDownload: () => void
  handleCopy: () => void
  setOpenPixModal: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSendMailModal: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const {
    state: {
      invoice,
    }
  } = useLocation();

  const {
    contract_id,
  } = useParams();

  const {
    user,
    openLoading,
    closeLoading
  } = useStore();

  const [ openPixModal, setOpenPixModal ] = useState<boolean>( false );
  const [ openSendMailModal, setOpenSendMailModal ] = useState<boolean>( false );

  const handleDownload = async () => {
    openLoading();

    await downloadInvoice( user?.token ?? "", Number( contract_id ), invoice );

    closeLoading();
  };

  const handleCopy = () => {
    enqueueSnackbar("Código copiado para área de transferência", {
      variant: "success",
    });
  };

  return {
    invoice,
    openPixModal,
    openSendMailModal,
    handleDownload,
    handleCopy,
    setOpenPixModal,
    setOpenSendMailModal,
  };
};
